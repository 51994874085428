/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 首页接口
 */

import BaseApi from '@/request/base/BaseApi';

class HomeApiSet {
  constructor () {
    this.coreData = BaseApi.createModel('获取核心数据列表', '/opmp/homePageData/coreData', true).setMethod(1);
    this.businessData = BaseApi.createModel('获取经营数据列表', '/opmp/homePageData/businessData', true).setMethod(1);
  }
}

export default new HomeApiSet();
